<template>
    <div class="home">
        <div class="level">
            <div class="level-left">
                <div class="level-item">
                    <h1 class="title is-4">{{ $t("Interface.Door.List.Title") }}</h1>
                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <b-button
                        class="button is-theme"
                        :loading="IsLoading"
                        @click.prevent="refresh()"
                    >
                        {{ $t("Interface.Door.List.PullButton") }}
                    </b-button>
                </div>
            </div>
        </div>
        <b-table
            :data="Doors"
            :striped="true"
            :mobile-cards="true"
            :paginated="true"
            :per-page="ItemsPerPage"
            :current-page.sync="PageNumber"
            :total="DoorsAmount"
            :loading="IsLoading"
            default-sort="doorId"
            icon-pack="fa"
            detailed
            detail-key="doorId"
        >
            <b-table-column v-slot="props" field="name" :label="$t('Interface.Door.Name')" sortable>
                {{ props.row.name }}
            </b-table-column>
            <b-table-column v-slot="props" field="address" :label="$t('Interface.Door.Address')" sortable>
                {{ props.row.address }}
            </b-table-column>
            <b-table-column v-slot="props" field="doorType" :label="$t('Interface.Door.DoorType')" sortable>
                {{ $t(`Interface.Door.Type.${props.row.doorType}`) }}
            </b-table-column>
            <b-table-column v-slot="props" field="doorProvider" :label="$t('Interface.Door.Provider')" sortable>
                {{ props.row.doorProvider }}
            </b-table-column>

            <template slot="detail" slot-scope="props">
                <article class="media">
                    <div class="media-content">
                        <div class="content">
                            <strong>
                                {{ props.row.name }} [{{ props.row.doorId }}] (External Id: {{ props.row.externalDoorId }})
                            </strong>
                            <br>
                            <br>
                            <dl>
                                <dt>{{ $t("Interface.Door.DoorType") }}:</dt>
                                <dd>{{ $t(`Interface.Door.Type.${props.row.doorType}`) }}</dd>
                            </dl>
                            <dl>
                                <dt>{{ $t("Interface.Door.Provider") }}:</dt>
                                <dd>{{ props.row.doorProvider }}</dd>
                            </dl>
                            <dl>
                                <dt>{{ $t("Interface.Door.Address") }}:</dt>
                                <dd>{{ props.row.address }}</dd>
                            </dl>
                            <dl>
                                <dt>{{ $t("Interface.Door.KYCrequired") }}:</dt>
                                <dd>{{ $t(`Interface.Door.BoolType.${props.row.kycRequired}`) }}</dd>
                            </dl>
                            <dl>
                                <dt>{{ $t("Interface.Door.Latitude") }}:</dt>
                                <dd>{{ props.row.latitude }}</dd>
                            </dl>
                            <dl>
                                <dt>{{ $t("Interface.Door.Longtitude") }}:</dt>
                                <dd>{{ props.row.longtitude }}</dd>
                            </dl>
                            <dl>
                                <dt>{{ $t("Interface.Door.Timezone") }}:</dt>
                                <dd>
                                    <template v-if="!(Math.sign(props.row.timeZone) === -1)">
                                        UTC+{{ props.row.timeZone }}
                                    </template>
                                    <template v-else>
                                        UTC{{ props.row.timeZone }}
                                    </template>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </article>
            </template>

            <b-table-column v-slot="props" field="actions" :label="$t('Interface.DoorProvider.List.Actions')">
                <router-link
                    class="has-text-info"
                    :to="{ name: 'Door-Edit', params: { id: props.row.doorId } }"
                >
                    <b-tooltip :label="$t('Interface.Button.Edit')" type="is-info" animated>
                        <font-awesome-icon :icon="['fas', 'pen-square']" size="lg" class="is-info" />
                    </b-tooltip>
                </router-link>
                <router-link
                    v-if="props.row.removable"
                    class="has-text-danger"
                    style="margin-left:0.5rem;"
                    :to="{ name: 'Door-Delete', params: { id: props.row.doorId } }"
                >
                    <b-tooltip :label="$t('Interface.Button.Delete')" type="is-danger" animated>
                        <font-awesome-icon :icon="['fas', 'times-circle']" size="lg" class="is-danger" />
                    </b-tooltip>
                </router-link>
            </b-table-column>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>{{ $t("Interface.Door.List.Empty") }}</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
import LoadingState from "@/mixins/LoadingState"
import Helpers from "@/utils/Helpers"
import {
    DOOR_LIST_REQUEST,
    DOOR_UPDATE_REQUEST
} from "@/store/actions/door"

export default {
    name: "DoorList",
    mixins: [LoadingState],
    data() {
        return {
            PageNumber: 1,
            ItemsPerPage: 25
        }
    },
    computed: {
        DoorsAmount() {
            return this.$store.getters.DOORS_AMOUNT
        },
        Doors() {
            return this.$store.state.Door.List
        }
    },
    mounted() {
        if (!this.$store.getters.IS_DOORS_LOADED) {
            this.loadDoors()
        }
    },
    methods: {
        loadDoors() {
            this.switchLoading()
            this.$store
                .dispatch(DOOR_LIST_REQUEST)
                .finally(() => this.switchLoading())
        },
        refresh() {
            this.switchLoading()
            this.$store
                .dispatch(DOOR_UPDATE_REQUEST)
                .then((response) => {
                    if (response.data.updated) {
                        this.loadDoors()
                    }
                    else Helpers.notify("is-info", this.$t("Message.Door.RefreshNoResult"))
                })
                .finally(() => this.switchLoading())
        }
    },
    metaInfo() {
        return {
            title: this.$t("Interface.Door.List.Title")
        }
    }
}
</script>

<style scoped>
    dl {
        width: 100%;
        overflow: hidden;
        padding: 0;
        margin: 0 !important;
        padding: 0.25rem 0;
    }
    dt {
        float: left;
        width: 30%;
        padding: 0;
        margin: 0
    }
    dd {
        float: left;
        width: 70%;
        padding: 0;
        margin: 0
    }
    dl:nth-child(2n+1) {
        background-color: #fefefe;
    }
</style>
